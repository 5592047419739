import React, { useEffect } from "react";
import styles from "./produtos.module.css";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import inst_brocolis from "../../assets/img/produtos/INST_Brocolis.png";
import inst_brocolis_2kg from "../../assets/img/produtos/INST_brocolis_2kg.png";
import inst_couveflor from "../../assets/img/produtos/INST_Couveflor.png";
import inst_ErvilhaFresca from "../../assets/img/produtos/INST_ErvilhaFresca.png";
import inst_seletadelegumes from "../../assets/img/produtos/INST_seletadelegumes.png";
import inst_jardineiracomervilhas from "../../assets/img/produtos/INST_jardineiracomervilhas.png";
import inst_jardineirapanache from "../../assets/img/produtos/INST_jardineirapanache.png";
import inst_jardineiraprimavera from "../../assets/img/produtos/INST_jardineiraprimavera.png";
import inst_polentapalito from "../../assets/img/produtos/INST_polentapalito.png";
import inst_PolentaNoisette from "../../assets/img/produtos/INST_PolentaNoisette.png";
import inst_vagemInteira from "../../assets/img/produtos/INST_VagemInteira.png";
import inst_vagemPedaco from "../../assets/img/produtos/INST_VagemPedaco.png";
import inst_espinafrePicado from "../../assets/img/produtos/INST_EspinafrePicado.png";
import inst_espinafreFolhas from "../../assets/img/produtos/INST_EspinafreFolhas.png";
import inst_cenouraBaby from "../../assets/img/produtos/INST_CenouraBaby.png";
import inst_cenouraCubos from "../../assets/img/produtos/INST_cenouracubos.png";
import inst_couveDeBruxelas from "../../assets/img/produtos/INST_CouveDeBruxelas.png";
import inst_mandiocaPalitos from "../../assets/img/produtos/INST_MandiocaPalitos.png";
import inst_mandiocaPedaco from "../../assets/img/produtos/INST_MandiocaPedaco.png";
import inst_aboboraCabotia from "../../assets/img/produtos/INST_AbóboraCabotiá.png";
import inst_aneisDeCebola from "../../assets/img/produtos/INST_AnéisdeCebola.png";
import inst_batataPalito from "../../assets/img/produtos/INST_BatataPalito.png";
import inst_couveFlorMacerata from "../../assets/img/produtos/INST_CouveFlorMacerata.png";
import atac_duetoDeLegumes from "../../assets/img/produtos/ATAC_DuetoDeLegumes.png";
import atac_brocolis from "../../assets/img/produtos/ATAC_brocolis.png";
import atac_couve_flor from "../../assets/img/produtos/ATAC_couve_flor.png";
import atac_ervilha_fresca from "../../assets/img/produtos/ATAC_ervilha_fresca.png";
import atac_milho from "../../assets/img/produtos/ATAC_Milho.png";
import atac_jardineiraComErvilhas from "../../assets/img/produtos/ATAC_jardineira_com_ervilhas.png";
import atac_alhoInteiro from "../../assets/img/produtos/ATAC_AlhoInteiro.png";
import atac_alhoPicado from "../../assets/img/produtos/ATAC_AlhoPicado.png";
import atac_polentapalitos from "../../assets/img/produtos/ATAC_polentaPalitos.png";
import atac_mandiocaPalitos from "../../assets/img/produtos/ATAC_MandiocaPalito.png";
import atac_mandiocaPedaco from "../../assets/img/produtos/ATAC_MandiocaPedaco.png";
import atac_paoDeQueijoTradicional from "../../assets/img/produtos/ATAC_PaoDeQueijoTradicional.png";
import atac_paoDeQueijoCoquetel from "../../assets/img/produtos/ATAC_PaoDeQueijoCoquetel.png";
import atac_paoDeQueijoSuperLanche from "../../assets/img/produtos/ATAC_PaoDeQueijoSuperLanche.png";
import var_brocolis from "../../assets/img/produtos/VAR_brocolis.png";
import var_couve_flor from "../../assets/img/produtos/VAR_couve_flor.png";
import var_ervilha_fresca from "../../assets/img/produtos/VAR_ervilha_fresca.png";
import var_seleta_de_legumes from "../../assets/img/produtos/VAR_seleta_de_legumes.png";
import VAR_jardineira_panache from "../../assets/img/produtos/VAR_jardineira_panache.png";
import var_polenta_palitos from "../../assets/img/produtos/VAR_polenta_palitos.png";
import ba_brocolis from "../../assets/img/produtos/BA_Brocolis.png";
import ba_couveFlor from "../../assets/img/produtos/BA_CouveFlor.png";
import ba_ervilha from "../../assets/img/produtos/BA_Ervilha.png";
import ba_jardineiraErvilha from "../../assets/img/produtos/BA_JardineiraErvilha.png";
import ba_jardineiraPanache from "../../assets/img/produtos/BA_JardineiraPanache.png";
import AOS from "aos";
import "aos/dist/aos.css";

function Produtos() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    document.title = "Produtos - Nutriz";

    const hash = window.location.hash;
    if (hash === "#bom-apetite") {
      setTimeout(() => {
        window.location.href = "https://nutriz.com.br/#bom-apetite";
      }, 10000);
    }
  }, []);

  return (
    <main className={styles.main_produtos}>
      <Header />
      <section className={styles.p_s1}>
        <p></p>
        <div>
          <h1 className={styles.h1_prod}>Alimentos Nutriz:</h1>
          <p className={styles.p_s1_descr}>
            Segurança, Saúde e Sabor em cada mordida
          </p>
        </div>
        <div className={styles.p_div_links}>
          <a href="#institucional">Institucional</a>
          <a href="#atacado">Atacado</a>
          <a href="#varejo">Varejo</a>
          <a href="#bom-apetite">Bom Apetite</a>
        </div>
        <div className={styles.p_div_links_mobile}>
          <div>
            <a href="#institucional">Institucional</a>
            <a href="#varejo">Varejo</a>
          </div>
          <div>
            <a href="#atacado">Atacado</a>
            <a href="#bom-apetite">Bom Apetite</a>
          </div>
        </div>
      </section>

      <p id="institucional" className={styles.p_id}></p>
      <section>
        <h1 className={styles.prod_h1_s2}>Linha Institucional</h1>
        <div className={styles.prod_s2}>
          <div className={styles.container_produtos}>
            <a href="/produto/brocolis-inst">
              <img src={inst_brocolis}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Brócolis 2,5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/brocolis-inst-2kg">
              <img src={inst_brocolis_2kg}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Brócolis 2kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/couve-flor-inst">
              <img src={inst_couveflor}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Couve-flor 2,5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/ervilha-fresca-inst">
              <img src={inst_ErvilhaFresca}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Ervilha Fresca 2,5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/seleta-de-legumes-inst">
              <img src={inst_seletadelegumes}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Seleta de Legumes 2,5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/jardineira-com-ervilhas-inst">
              <img src={inst_jardineiracomervilhas}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Jardineira com Ervilhas 2,5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/jardineira-panache-inst">
              <img src={inst_jardineirapanache}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Jardineira Panachê 2,5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/jardineira-primavera-inst">
              <img src={inst_jardineiraprimavera}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Jardineira Primavera 2,5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/polenta-palito-inst">
              <img src={inst_polentapalito}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Polenta Palito 2,5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/polenta-noisette-inst">
              <img src={inst_PolentaNoisette}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Polenta Noisette 2,5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/vagem-inteira-inst">
              <img src={inst_vagemInteira}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Vagem Inteira 2,5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/vagem-pedacos-inst">
              <img src={inst_vagemPedaco}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Vagem Pedaços 2,5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/espinafre-picado-inst">
              <img src={inst_espinafrePicado}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Espinafre Picado 2,5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/espinafre-folhas-inst">
              <img src={inst_espinafreFolhas}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Espinafre Folhas 2,5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/cenoura-baby-inst">
              <img src={inst_cenouraBaby}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Cenoura Baby 2,5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/cenoura-em-cubos-inst">
              <img src={inst_cenouraCubos}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Cenoura em cubos 2,5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/couve-de-bruxelas-inst">
              <img src={inst_couveDeBruxelas}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Couve-de-Bruxelas 2,5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/mandioca-palitos-inst">
              <img src={inst_mandiocaPalitos}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Mandioca Palitos 2,5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/mandioca-pedacos-inst">
              <img src={inst_mandiocaPedaco}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Mandioca Pedaços 2,5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/abobora-cabotia-inst">
              <img src={inst_aboboraCabotia}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Abobóra Cabotiá 2,5kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/aneis-de-cebola-inst">
              <img src={inst_aneisDeCebola}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Anéis de Cebola Leduc 1,1kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/batata-palito-inst">
              <img src={inst_batataPalito}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Batata Palito Temperada 2kg</p>
          </div>

          <div className={styles.container_produtos}>
            <a href="/produto/couve-flor-branqueado-inst">
              <img src={inst_couveFlorMacerata}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Couve-Flor Macerata 2kg</p>
          </div>

        </div>
      </section>

      <p id="atacado" className={styles.p_id}></p>
      <section>
        <h1
          className={styles.prod_h1_s2}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          Linha Atacado
        </h1>
        <div className={styles.prod_s2}>
          <div className={styles.container_produtos}>
            <a href="/produto/brocolis-atac">
              <img src={atac_brocolis}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Brócolis 1,02kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/couve-flor-atac">
              <img src={atac_couve_flor}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Couve-flor 1,02kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/ervilha-fresca-atac">
              <img src={atac_ervilha_fresca}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Ervilha Fresca 1,1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/milho-atac">
              <img src={atac_milho}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Milho Grãos 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/jardineira-com-ervilhas-atac">
              <img src={atac_jardineiraComErvilhas}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Jardineira com Ervilhas 1,02kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/alho-inteiro-atac">
              <img src={atac_alhoInteiro}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Alho Inteiro 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/alho-picado-atac">
              <img src={atac_alhoPicado}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Alho Picado 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/polenta-palitos-atac">
              <img src={atac_polentapalitos}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Polenta Palitos 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/mandioca-palito-atac">
              <img src={atac_mandiocaPalitos}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Mandioca Palitos 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/mandioca-pedaco-atac">
              <img src={atac_mandiocaPedaco}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Mandioca Pedaços 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/pao-de-queijo-tradicional-atac">
              <img src={atac_paoDeQueijoTradicional}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Pão de Queijo Tradicional 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/pao-de-queijo-coquetel-atac">
              <img src={atac_paoDeQueijoCoquetel}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Pão de Queijo Coquetel 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/pao-de-queijo-super-lanche-atac">
              <img src={atac_paoDeQueijoSuperLanche}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p className={styles.paodequeijo}>Pão de Queijo Super Lanche 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/dueto-de-legumes-atac">
              <img src={atac_duetoDeLegumes}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p className={styles.paodequeijo}>Dueto de Legumes 1kg</p>
          </div>
        </div>
      </section>

      <p id="varejo" className={styles.p_id}></p>
      <section>
        <h1
          className={styles.prod_h1_s2}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          Linha Varejo
        </h1>
        <div className={styles.prod_s2}>
          <div className={styles.container_produtos}>
            <a href="/produto/brocolis-var">
              <img src={var_brocolis}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Brócolis 300g</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/couve-flor-var">
              <img src={var_couve_flor}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Couve-flor 300g</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/ervilha-fresca-var">
              <img src={var_ervilha_fresca}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Ervilha Fresca 300g</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/seleta-de-legumes-var">
              <img src={var_seleta_de_legumes}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Seleta de Legumes 300g</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/jardineira-panache-var">
              <img src={VAR_jardineira_panache}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Jardineira Panachê 300g</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/polenta-palito-var">
              <img src={var_polenta_palitos}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Polenta Palitos 400g</p>
          </div>
        </div>
      </section>

      <p id="bom-apetite" className={styles.p_id}></p>
      <section>
        <h1
          className={styles.prod_h1_s2}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          Linha Bom Apetite
        </h1>
        <div className={styles.prod_s2}>
          <div className={styles.container_produtos}>
            <a href="/produto/brocolis-bom-apetite">
              <img src={ba_brocolis}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Brócolis 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/couve-flor-bom-apetite">
              <img src={ba_couveFlor}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Couve-flor 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/ervilha-bom-apetite">
              <img src={ba_ervilha}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Ervilha 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/jardineira-com-ervilhas-bom-apetite">
              <img src={ba_jardineiraErvilha}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Jardineira com Ervilhas 1kg</p>
          </div>
          <div className={styles.container_produtos}>
            <a href="/produto/jardineira-panache-bom-apetite">
              <img src={ba_jardineiraPanache}></img>
              <p className={styles.leia_mais}>LEIA MAIS</p>
            </a>
            <p>Jardineira Panachê 1kg</p>
          </div>
        </div>
      </section>

      <Footer />
    </main>
  );
}

export default Produtos;
