// agendar.js
import React, { useState, useEffect } from 'react';
import logo from "../../assets/img/logo.png";
import trator from "../../assets/img/trator.png";
import styles from './estilo.module.css'; 

const formatarData = (dataISO) => {
  const data = new Date(dataISO);
  const dia = String(data.getDate()).padStart(2, '0'); 
  const mes = String(data.getMonth() + 1).padStart(2, '0'); 
  const ano = data.getFullYear();

  return `${dia}/${mes}/${ano}`;
};

// Componente Modal
const Modal = ({ isOpen, onClose, onConfirm, agendamento }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2 className={styles.tituloModal}>Agendamento Encontrado</h2>
        <p className={styles.paraginicial}>Você já possui um agendamento com as seguintes informações:</p>
        <ul>
          <li><strong>Data:</strong> {formatarData(agendamento.AG_DATA)}</li>
          <li><strong>Hora:</strong> {agendamento.AG_HORA}</li>
          <li><strong>Produto:</strong> {agendamento.AG_ITEM}</li>
          <li><strong>Quantidade:</strong> {agendamento.AG_QTD} KG</li>
        </ul>
        <p className={styles.parag}>Deseja atualizar o agendamento existente ou criar um novo?</p>
        <div className={styles.modalActions}>
        <button onClick={onClose} className={styles.botaoFechar}>Fechar</button>
          <button onClick={() => onConfirm('atualizar')} className={styles.botaoConfirmar}>Atualizar</button>
          <button onClick={() => onConfirm('criarNovo')} className={styles.botaoCancelar}>Criar Novo</button>
        </div>
        
      </div>
    </div>
  );
};

const Agendar = () => {
  const [cpf, setCpf] = useState('');
  const [bemVindo, setBemVindo] = useState('Bem vindo');
  const [dataHora, setDataHora] = useState('');
  const [produto, setProduto] = useState('');
  const [quantidade, setQuantidade] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [agendamentoExistente, setAgendamentoExistente] = useState(null);
  const [deAte, setDeAte] = useState({ de: '', ate: '' });

  // Função para formatar CPF
  const ajustaCPF = (valor) => {
    let limpaValorCpf = valor.replace(/\D/g, '');
    let arrayCPF = limpaValorCpf.split('');
    let cpfFormatado = '';

    if (arrayCPF.length <= 11) {
      if (arrayCPF.length > 0) cpfFormatado += `${arrayCPF.slice(0, 3).join('')}`;
      if (arrayCPF.length > 3) cpfFormatado += `.${arrayCPF.slice(3, 6).join('')}`;
      if (arrayCPF.length > 6) cpfFormatado += `.${arrayCPF.slice(6, 9).join('')}`;
      if (arrayCPF.length > 9) cpfFormatado += `-${arrayCPF.slice(9, 11).join('')}`;
    } else {
      if (arrayCPF.length > 0) cpfFormatado += `${arrayCPF.slice(0, 2).join('')}`;
      if (arrayCPF.length > 2) cpfFormatado += `.${arrayCPF.slice(2, 5).join('')}`;
      if (arrayCPF.length > 5) cpfFormatado += `.${arrayCPF.slice(5, 8).join('')}`;
      if (arrayCPF.length > 8) cpfFormatado += `/${arrayCPF.slice(8, 12).join('')}`;
      if (arrayCPF.length > 12) cpfFormatado += `-${arrayCPF.slice(12, 14).join('')}`;
    }

    return cpfFormatado;
  };

  const handleCpfChange = (e) => {
    setCpf(ajustaCPF(e.target.value));
  };

  const handleCpfBlur = async () => {
    if (cpf.length === 14 || cpf.length === 18) {
      try {
        // let response = await fetch(`http://192.168.2.252:8000/api/getUserName?cpf=${cpf}`);
        let response = await fetch(`https://agenda.nutriz.com.br.10-99-0-153.193.123.103.2.m9.network/api/getUserName?cpf=${cpf}`);
        if (response.status === 404) {
          alert('Nenhum usuário encontrado para o CPF/CNPJ informado.');
        } else if (response.ok) {
            // console.log(response);
          let data = await response.json();
          setBemVindo(`Bem vindo ${data.nome_user}`);
        }
      } catch (error) {
        console.error('Erro na requisição:', error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (cpf.length !== 14 && cpf.length !== 18) {
      alert('O CAMPO CPF/CNPJ DEVE CONTER 11 DÍGITOS PARA CPF OU 14 DÍGITOS PARA CNPJ!');
      return;
    }

    if (cpf && dataHora && produto && quantidade) {
      try {
        const dtHrAtual = new Date();
        const dtMoment = new Date(dataHora);
    
        if (dtMoment > dtHrAtual) {
          // let response = await fetch('http://192.168.2.252:8000/api/enviaDados', {
          let response = await fetch('https://agenda.nutriz.com.br.10-99-0-153.193.123.103.2.m9.network/api/enviaDados', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ cpf, dataHora, produto, quantidade }),
          });
    
          const result = await response.json();
    
          if (result.agendamentoExistente) {
            setAgendamentoExistente(result.dados[0]);
            setIsModalOpen(true);
          } else if (response.status === 404) {
            alert('Nenhum usuário encontrado para o CPF/CNPJ informado.');
          } else if (response.ok) {
            alert(result.message);
            window.location.reload();
          } else {
            alert('Erro ao enviar os dados:', response.statusText);
          }
        } else {
          alert('NÃO É PERMITIDO ESCOLHER UMA DATA E HORA MENOR QUE A DATA E HORA ATUAL!');
        }
      } catch (error) {
        console.error('Erro na requisição:', error);
      }
    }    
  };

  const handleModalConfirm = async (decisao) => {
    try {
      // let decisionResponse = await fetch('http://192.168.2.252:8000/api/resPerg', {
      let decisionResponse = await fetch('https://agenda.nutriz.com.br.10-99-0-153.193.123.103.2.m9.network/api/resPerg', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id_user: agendamentoExistente.AG_USER,
          dataHora,
          prod: agendamentoExistente.AG_ITEM,
          quantidade,
          decisao,
          idAgendamento: agendamentoExistente.AG_ID 
        }),
      });

      if (decisionResponse.ok) {
        alert(await decisionResponse.text());
        window.location.reload();
      } else {
        alert('Erro ao processar a decisão.');
      }
    } catch (error) {
      console.error('Erro ao processar a decisão:', error);
    } finally {
      setIsModalOpen(false); // Fecha o modal após a confirmação
    }
  };

  useEffect(() => {
    const fetchTextHora = async () => {
      try {
        // let response = await fetch(`http://192.168.2.252:8000/api/text`);
        let response = await fetch(`https://agenda.nutriz.com.br.10-99-0-153.193.123.103.2.m9.network/api/text`);
        let data = await response.json();
        setDeAte(data);
      } catch (error) {
        console.error('Erro ao processar a textHora:', error);
      }
    };

    fetchTextHora();
  }, []);

  return (
    <div className={styles.bodyAgendar}>
      <header className={styles.cabecalhoPrincipal}>
        <img src={logo} width="110px" className={styles.imagemLogo} alt="Logo" />
      </header>
      <form className={styles.formularioPrincipal} onSubmit={handleSubmit}>
        <div className={styles.divGeral}>
          <div className={styles.divTitulo}>
            <div className={styles.tituloPrincipal}>
              <h1 className={styles.tituloGeral}>Agendamento de Entrega</h1>
              <img src={trator} width="40px" className={styles.imagemTrator} alt="Trator" />
            </div>
            <h2 id="bem_vindo" className={styles.subtitulo}>{bemVindo}!</h2>
          </div>

          <div className={styles.subdivisao}>
            <div className={styles.divCPF}>
              <h2 className={styles.tituloCpf}>CPF/CNPJ do Produtor:</h2>
              <input
                className={styles.inputCpf}
                id="campo_cpf"
                placeholder="000.000.000-00"
                type="text"
                value={cpf}
                onChange={handleCpfChange}
                onBlur={handleCpfBlur}
                required
              />
            </div>

            <div className={styles.dataEntrega}>
              <h2 className={styles.tituloEntrega}>Data e Hora de Entrega:</h2>
              <input
                autoComplete="off"
                className={styles.inputData}
                type="datetime-local"
                id="dtHora"
                value={dataHora}
                onChange={(e) => setDataHora(e.target.value)}
                required
              />
            </div>

            <div className={styles.divProdutos}>
              <h2 className={styles.tituloProdutos}>Produto:</h2>
              <select
                className={styles.seletorProdutos}
                id="produtos"
                value={produto}
                onChange={(e) => setProduto(e.target.value)}
                required
              >
                <option value="" disabled hidden>Selecione o Produto</option>
                <option value="Brócolis">Brócolis</option>
                <option value="Couve">Couve</option>
                <option value="Cenoura">Cenoura</option>
                <option value="Batata">Batata</option>
              </select>
            </div>

            <div className={styles.quantidade}>
              <h2 className={styles.quantidadeEntrega}>Quantidade(KG):</h2>
              <input
                autoComplete="off"
                type="number"
                className={styles.inputQuantidade}
                id="quantidade"
                value={quantidade}
                onChange={(e) => setQuantidade(e.target.value)}
                required
              />
            </div>

            <button id="submit" className={styles.botaoEnviar} type="submit">Enviar</button>
            <p className={styles.aviso}>Atenção! Descarga a partir das {deAte.de} até as {deAte.ate}</p>
          </div>
        </div>
      </form>

      {/* Modal de confirmação */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleModalConfirm}
        agendamento={agendamentoExistente}
      />

      <footer className={styles.rodape}>
      </footer>
    </div>
  );
};

export default Agendar;
