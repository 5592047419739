import React, { useEffect } from "react";
import styles from "./linktree.module.css";
import logo from "../../assets/img/logo_linktree.png";

function linktree () {
  // useEffect(() => {
  //   document.title = "LinkTree - Nutriz";
  // }, []);

  return (
    <main className={styles.main_linktree}>
     <img src={logo} alt="Logo Nutriz" className={styles.logo_lt}></img>
     <div className={styles.div_descr_lt}>
      <h1>Nutriz Alimentos Congelados</h1>
      <p>O frescor que você deseja, <br></br> a praticidade que você precisa.</p>
     </div>
     <div className={styles.div_links_lt}>
      <a href="https://nutriz.com.br/">Site</a>
      <a href="https://www.instagram.com/nutrizalimentos/">Instagram</a>
      <a href="https://www.facebook.com/nutrizalimentos">Facebook</a>
      <a href="https://www.linkedin.com/company/nutriz-industria-ltda/">LinkedIn</a>
      <a href="https://nutriz.com.br/certificacoes/">Certificações</a>
      <a href="https://nutriz.com.br/contato/">Fale Conosco</a>
     </div>
    </main>
  );
}

export default linktree;
